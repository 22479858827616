import axios from "axios";
import {
  requestHandler,
  successHandler,
  errorHandler,
} from "../../interceptors";
import { getPayorSessionId } from "../../../utills/LocalStorage";

// Create an Axios instance for JSON data
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + '/payor',
});

// Request interceptor for JSON data
axiosInstance.interceptors.request.use((config) => {
  const token = getPayorSessionId();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return requestHandler(config);
});

// Response interceptor for JSON data
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

// Create an Axios instance for multipart/form-data
const axiosInstanceMultipart = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + '/payor',
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

axiosInstanceMultipart.interceptors.request.use((config) => {
  const token = getPayorSessionId();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return requestHandler(config);
});

// Response interceptor for multipart/form-data
axiosInstanceMultipart.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export { axiosInstance, axiosInstanceMultipart };
