import React from "react";
import { Route, Routes } from "react-router-dom";
import * as LazyComponent from "../../utills/LazyLoaded.js";
import { encashmentStoreRoutes } from "../../containers/EncashmentStore/EncashmentStoreRoutes";
import { USER_TYPE_ENCASHMENT_STORE } from "../../utills/Constants.js";

const EncashmentStoreRoutes = () => {

    return (
        <Routes>
            <Route exact path={encashmentStoreRoutes.DEFAULT} element={<LazyComponent.Cashcheck />} />
            <Route exact path={encashmentStoreRoutes.TRANSACTIONS} element={<LazyComponent.EncashmentStoreTransactions />} />
            <Route exact path={encashmentStoreRoutes.SETTINGS} element={<LazyComponent.Settings USER_TYPE={USER_TYPE_ENCASHMENT_STORE} />} />
        </Routes>
    );
};

export default EncashmentStoreRoutes;
