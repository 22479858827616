import LazyComponent from "./LazyComponent";

export const Dashboard = LazyComponent(() =>
  import("../containers/Payor/Dashboard/Dashboard")
);

export const SignIn = LazyComponent(() =>
  import("../containers/Payor/SignIn/SignIn")
);

export const PayeeManagement = LazyComponent(() =>
  import("../containers/Payor/PayeeManagement/PayeeManagement")
)

export const Transactions = LazyComponent(() =>
  import("../containers/Payor/Transactions/Transactions")
)

export const Settings = LazyComponent(() =>
  import("../components/Settings/Settings")
)

export const Cashcheck = LazyComponent(() =>
  import("../containers/EncashmentStore/CashCheck/CashCheck")
)

export const EncashmentStoreTransactions = LazyComponent(() =>
  import("../containers/EncashmentStore/Transactions/Transactions")
)

export const AdminDashboard = LazyComponent(() =>
  import("../containers/Admin/Dashboard/Dashboard")
)

export const AdminStoreManagement = LazyComponent(() =>
  import("../containers/Admin/StoreManagement/StoreManagement")
)
export const AdminPayorManagement = LazyComponent(() =>
  import("../containers/Admin/PayorManagement/PayorManagement")
)

export const AdminTransactions = LazyComponent(() =>
  import("../containers/Admin/Transactions/Transactions")
)

export const AdminSettings = LazyComponent(() =>
  import("../components/Settings/Settings")
)

export const AdminCompanies = LazyComponent(() =>
  import("../containers/Admin/Companies/Companies")
)