import React from 'react'
import Container from './containers'

const App = () => {
  return (
      <Container/>
  )
}

export default App
