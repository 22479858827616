import React from "react";
import { Routes, Route } from "react-router-dom";
import * as LazyComponent from "../../utills/LazyLoaded.js";
import { payorRoutes } from "../../containers/Payor/PayorRoutes";
import { USER_TYPE_PAYOR } from "../../utills/Constants.js";


const PayorRoutes = () => {


  return (
    <Routes>
      <Route path={payorRoutes.DEFAULT} element={<LazyComponent.Dashboard />} />
      <Route path={payorRoutes.PAYEE_MANAGEMENT} element={<LazyComponent.PayeeManagement />} />
      <Route path={payorRoutes.TRANSACTIONS} element={<LazyComponent.Transactions />} />
      <Route path={payorRoutes.SETTINGS} element={<LazyComponent.Settings USER_TYPE={USER_TYPE_PAYOR}/>} />
    </Routes>
  );
};

export default PayorRoutes;
