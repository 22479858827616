import { axiosInstance, axiosInstanceMultipart } from "../../network/apis/encashmentStore";


export const encashmentStoreLogin = async (payload) => {
    return await axiosInstance.post('/login', payload);
};

export const updateEncashmentStoreDetailsById = async (payload) => {
    return await axiosInstance.put(`${payload.id}`, { firstName: payload.firstName, lastName: payload.lastName });
}

export const updateEncashmentStoreCompanyProfile = async (payload) => {
    return await axiosInstance.put(`/${payload.id}/company`, payload);
}

export const uploadEncashmentStoreAccountPhoto = async (payload) => {
    return await axiosInstanceMultipart.put(`${payload.id}/image/upload`, payload.img);
}

export const removeEncashmentStoreAccountPhoto = async (payload) => {
    return await axiosInstance.put(`${payload.id}/image/delete`);
}

export const uploadEncashmentStoreCompanyProfileLogo = async (payload) => {
    return await axiosInstanceMultipart.put(`/${payload.id}/logo/company/${payload.companyId}/upload`, payload.img);
}

export const removeEncashmentStoreCompanyProfileLogo = async (payload) => {
    return await axiosInstance.put(`/${payload.id}/logo/company/${payload.companyId}/delete`);
}

export const getPayeeChecksForPhoneNumber = async (payload) => {
    return await axiosInstance.get(`${payload.payorId}/payee/${payload.search}/checks`);
}

export const createEncashmentStore = async (payload) => {
    return await axiosInstance.post(`${payload.storeAdminId}/store`, payload);
}

export const getAllStores = async (payload) => {
    return await axiosInstance.get(`${payload.storeId}/stores`)
}

export const getStoreDetails = async (payload) => {
    return await axiosInstance.get(`${payload.storeId}/store/${payload.selectedStoreId}`)
}

export const updateStoreDetails = async (payload) => {
    return await axiosInstance.put(`${payload.storeId}/store/${payload.selectedStoreId}`, payload)
}

export const uploadStoreLogo = async (payload) => {
    return await axiosInstanceMultipart.put(`${payload.storeId}/store/${payload.selectedStoreId}/logo/upload`, payload.img)
}

export const removeStoreLogo = async (payload) => {
    return await axiosInstance.put(`${payload.storeId}/store/${payload.selectedStoreId}/logo/delete`, payload)
}

export const addStoreUser = async (payload) => {
    return await axiosInstance.post(`${payload.storeId}/store/${payload.selectedStoreId}/user`, payload)
}

export const updateStoreUser = async (payload) => {
    return await axiosInstance.put(`${payload.storeId}/store/${payload.selectedStoreId}/user/${payload.storeUserId}`, payload)
}

export const deleteStoreUser = async (payload) => {
    return await axiosInstance.delete(`${payload.storeId}/store/${payload.selectedStoreId}/user/${payload.storeUserId}`)
}

export const generateOtpCode = async (payload) => {
    return await axiosInstance.post(`${payload.encashmentUserId}/generate/transaction/code`, payload)
}

export const verifyOtpCode = async (payload) => {
    return await axiosInstance.post(`${payload.encashmentUserId}/verify/transaction/code`, payload)
}

export const cashCheckTransaction = async (payload) => {
    return await axiosInstance.post(`${payload.encashmentUserId}}/cash/check`, payload)
}

export const getFraudulentReasons = async (payload) => {
    return await axiosInstance.get(`${payload.encashmentUserId}/flag/reasons`)
}

export const flagCheck = async (payload) => {
    return await axiosInstance.post(`${payload.encashmentUserId}/flag/check`, payload)
}

export const uploadDrivingLicense = async (payload) => {
    const { encashmentUserId } = JSON.parse(payload.body)
    return await axiosInstanceMultipart.put(`${encashmentUserId}/upload/driving/license`, payload)
}

    export const getTransactionsFilters = async (payload) => {
    return await axiosInstance.get(`${payload.encashmentStoreUserId}/transaction/filters`)
}

export const getTransactions = async (payload) => {
    return await axiosInstance.post(`${payload.encashmentStoreUserId}/transactions`, payload)
}

export const activateDeactivateStore = async (payload) => {
    return await axiosInstance.delete(`${payload.encashmentStoreUserId}/store/activate/deactivate`, { data: payload })
}

export const activateDeactivateStoreUser = async (payload) => {
    return await axiosInstance.delete(`${payload.encashmentStoreUserId}/user/activate/deactivate`, { data: payload })
}