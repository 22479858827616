exports.adminRoutes = {
    INITIAL: "/",
    DEFAULT: "/admin/dashboard",
    PAYOR_MANAGEMENT: "/admin/payor-management",
    STORE_MANAGEMENT: "/admin/store-management",
    COMPANIES: "/admin/companies",
    TRANSACTIONS: "/admin/transactions",
    SETTINGS: "/admin/settings",
    SUPPORT: "/admin/support"
};
exports.adminAuthRoutes = {
    //SIGN IN AND OTHER ROUTES
    FORGOT_PASSWORD: "/admin/forgotPassword",
    RESET_PASSWORD: "/admin/resetPassword",
    SIGN_IN: "/admin/signin"
}
