import { axiosInstance, axiosInstanceMultipart } from "../../network/apis/admin";


export const adminLogin = async (payload) => {
    return await axiosInstance.post('/login', payload);
};

export const getDashboardStatistics = async (payload) => {
    return await axiosInstance.get(`/${payload.adminId}/statistics`);
}

export const getLatestTransactions = async (payload) => {
    return await axiosInstance.get(`/${payload.adminId}/latest/transactions/${payload.filter}`);
}

export const getDashboardAlerts = async (payload) => {
    return await axiosInstance.get(`${payload.adminId}/alerts/${payload.filter}`)
}

export const getAllPayors = async (payload) => {
    return await axiosInstance.post(`${payload.adminId}/payors`, payload)
}

export const adminAddPayor = async (payload) => {
    return await axiosInstance.post(`/payor/create`, payload)
}

export const getFraudulentReasons = async (payload) => {
    return await axiosInstance.get(`${payload.adminId}/flag/reasons`)
}

export const addFraudulentReasons = async (payload) => {
    return await axiosInstance.post(`/flag/reason`, payload)
}

export const deleteFraudulentReasons = async (payload) => {
    return await axiosInstance.delete(`${payload.adminId}/flag/reason/${payload.reasonId}`)
}

export const updateFraudulentReasons = async (payload) => {
    const { adminId, ...restPayload } = payload;
    return await axiosInstance.put(`${adminId}/flag/reason`, restPayload);
};

export const activateDeactivateDeletePayor = async (payload) => {
    return await axiosInstance.put(`${payload.adminId}/payor/${payload.payorId}/${payload.actionType}`)
}

export const getEncashmentStores = async (payload) => {
    return await axiosInstance.post(`${payload.adminId}/encashmentusers`, payload)
}

export const createEncashmentUser = async (payload) => {
    return await axiosInstance.post('encashment/create', payload)
}
export const activateDeactivateDeleteEncashmentUsers = async (payload) => {
    return await axiosInstance.put(`${payload.adminId}/encashment/${payload.payorId}/${payload.actionType}`)
}

export const adminGetAllTransactions = async (payload) => {
    return await axiosInstance.post(`${payload.adminId}/transactions`, payload)
}

export const adminGetDetailedTransaction = async (payload) => {
    return await axiosInstance.get(`${payload.adminId}/transaction/${payload.transactionId}`)
}

export const uploadAdminAccountImg = async (payload) => {
    return await axiosInstanceMultipart.put(`${payload.id}/image/upload`, payload.img)
}

export const removeAdminAccountImg = async (payload) => {
    return await axiosInstanceMultipart.put(`${payload.id}/image/delete`)
}

export const updateAdminAccountDetails = async (payload) => {
    return await axiosInstance.put(`${payload.id}`, payload)
}

export const getTransactionsFilters = async (payload) => {
    return await axiosInstance.get(`${payload.adminId}/filters/transaction`)
}

export const getStoreDetails = async (payload) => {
    return await axiosInstance.get(`${payload.adminId}/encashment/${payload.storeId}`)
}

export const adminGetCompanies = async (payload) => {
    return await axiosInstance.post(`${payload.adminId}/companies`, payload)
}