import React from "react";
import { Routes, Route } from "react-router-dom";
import * as LazyComponent from "../../utills/LazyLoaded.js";
import { encashmentStoreAuthRoutes as encashmentStoreAuthRoutesPath } from "../../containers/EncashmentStore/EncashmentStoreRoutes.js";
import { USER_TYPE_ENCASHMENT_STORE } from "../../utills/Constants.js";



const EncashmentStoreAuthRoutes = () => {

    return (
        <Routes>
            <Route path={encashmentStoreAuthRoutesPath.SIGN_IN} element={<LazyComponent.SignIn USER_TYPE={USER_TYPE_ENCASHMENT_STORE} />} />
            <Route path={encashmentStoreAuthRoutesPath.FORGOT_PASSWORD}  element={<LazyComponent.SignIn USER_TYPE={USER_TYPE_ENCASHMENT_STORE} forgotPasswordScreen/>} />

        </Routes>
    );
};

export default EncashmentStoreAuthRoutes;
