import { configureAdmin, configureEncashmentStore, configurePayor } from "./Aws-amplify";
import { storeAdminSessionId, storeEncashmentStoreSessionId, storePayorSessionId } from "./LocalStorage";
import { USER_TYPE_ADMIN, USER_TYPE_ENCASHMENT_STORE, USER_TYPE_PAYOR } from "./Constants";
import { fetchAuthSession, signIn, signOut, confirmSignIn, resetPassword, getCurrentUser, updatePassword, confirmResetPassword, fetchUserAttributes } from 'aws-amplify/auth'


const configureAWS = (userType) => {
    switch (userType) {
        case USER_TYPE_PAYOR:
            configurePayor();
            break;
        case USER_TYPE_ENCASHMENT_STORE:
            configureEncashmentStore();
            break;
        case USER_TYPE_ADMIN:
            configureAdmin();
            break;
        default:
            break;
    }
}

const errMsgMap = {
    "Invalid code or auth state for the user.": "The code you entered is incorrect",
    "Too many invalid credentials attempts. User temporarily locked. Please try again after few seconds.": "Please try again after few seconds.",
    "Attempt limit exceeded, please try after some time.": "Please try after some time."
}

const getUserFriendlyErrorMessage = (error) => {
    const errMsg = errMsgMap[error.message]
    const err = errMsg ? errMsg : error.message
    return err
}

async function cognitosignIn(
    userName,
    password,
    successCallBack,
    failureCallBack,
    userType
) {
    try {
        await configureAWS(userType);
        const user = await signIn({
            username: userName,
            password: password,
        });

        successCallBack(user, password);

    } catch (error) {
        failureCallBack(error.message);
    }
}

async function confirmsignIn(username, mfaCode, successCallBack, failureCallBack) {

    try {
        const user = await confirmSignIn({ challengeResponse: mfaCode });
        successCallBack(user);
    }
    catch (error) {
        const errMsg = getUserFriendlyErrorMessage(error);
        failureCallBack(errMsg)
    }
}

async function resetPasswordWithTempPassword(newPassword, successCallback, failureCallback, userType) {
    await configureAWS(userType);
    try {
        const response = await confirmSignIn({ challengeResponse: newPassword });
        successCallback(response)
    }
    catch (error) {
        failureCallback(error)
    }

}

async function getUserSession(userType) {

    await configureAWS(userType);
    try {
        const { tokens: session } = await fetchAuthSession({ forceRefresh: true });
        if (session) {
            switch (userType) {
                case USER_TYPE_PAYOR:
                    storePayorSessionId(session.accessToken.toString());
                    break;
                case USER_TYPE_ENCASHMENT_STORE:
                    storeEncashmentStoreSessionId(session.accessToken.toString());
                    break;
                default:
                    storeAdminSessionId(session.accessToken.toString());
            }

            return { session, error: false };
        } else {
            return { error: true };
        }
    } catch (error) {
        return { error: true };
    }
}

function getErrorMessageForErrorCode(errorCode) {
    switch (errorCode) {
        case "UsernameExistsException":
            return "Email id already exists. Please try different email Id for SignUp.";
        case "NotAuthorizedException":
            return "Incorrect username or password";
        case "CodeMismatchException":
            return "Verification code is not valid.";
        case "InvalidParameterException":
            return "";
        case "InvalidPasswordException":
            return "Password is not valid";
        default:
            return "";
    }
}

async function CognitoSignOut(userType) {
    try {
        configureAWS(userType);
        await signOut({ global: true });
    } catch (error) {
        console.log("error signing out: ", error.toString());
    }
}

async function CognitoForgotPassword(
    userEmail,
    successCallBack,
    failureCallBack,
    userType
) {
    try {
        configureAWS(userType);
        console.log("userEmail--- " + userEmail);
        const user = await resetPassword({ username: userEmail });
        successCallBack(user);
    } catch (error) {
        console.log("forgotpassword error", error);
        const errorCode = error?.toString().split(':')[0].trim();
        var errorMessage = getErrorMessageForErrorCode(errorCode);
        errorMessage = errorMessage ? errorMessage : error.message;
        failureCallBack(errorMessage);
    }
}

async function CognitoResetPassword(
    userEmail,
    verificationCode,
    newPassword,
    successCallBack,
    failureCallBack,
    userType
) {
    try {
        configureAWS(userType);
        const user = await confirmResetPassword({
            username: userEmail,
            confirmationCode: verificationCode,
            newPassword: newPassword
        });
        successCallBack(user);
    } catch (error) {
        const errMsg = getUserFriendlyErrorMessage(error);
        failureCallBack(errMsg);
    }
}


async function getFullAuthObject(userType) {
    try {
        configureAWS(userType);
        const {
            username,
            userId: id
        } = await getCurrentUser();

        const attributes = await fetchUserAttributes();

        return {
            id,
            username,
            attributes
        };

    } catch (error) {
        if (error.name === "NotAuthorizedException") {
            CognitoSignOut(userType)
        }
        else {
            throw new Error(error)
        }
    }

}


async function CognitoChangePassword(oldPassword, newPassword, userType) {
    try {
        configureAWS(userType);
        console.log("reset password---- ", oldPassword, newPassword);

        const changePasswordResult = await updatePassword({
            oldPassword,
            newPassword
        });
        console.log("reset password -- ", changePasswordResult);
        return { result: changePasswordResult, error: false };

    } catch (error) {
        console.log("reset password exception-- --- ", error);
        var errorMsg = error.message;
        if (error.message === "Incorrect username or password.") {
            errorMsg = "Old password is not a correct password";
        }
        return { message: errorMsg, error: true };
    }
}

export {
    cognitosignIn, getUserSession, resetPasswordWithTempPassword,
    confirmsignIn, CognitoSignOut, CognitoForgotPassword,
    CognitoResetPassword, getFullAuthObject, CognitoChangePassword
}