export const PAYOR_LOGIN_INFO = 'PAYOR_LOGIN_INFO';
export const PAYOR_DETAILS_UPDATE = 'PAYOR_DETAILS_UPDATE';
export const PAYOR_UPLOAD_ACCOUNT_IMG = 'PAYOR_UPLOAD_ACCOUNT_IMG';
export const PAYOR_REMOVE_ACCOUNT_IMG = 'PAYOR_REMOVE_ACCOUNT_IMG';
export const PAYOR_UPDATE_COMPANY_PROFILE = 'PAYOR_UPDATE_COMPANY_PROFILE';
export const PAYOR_UPLOAD_COMPANY_LOGO = 'PAYOR_UPLOAD_COMPANY_LOGO';
export const PAYOR_REMOVE_COMPANY_LOGO = 'PAYOR_REMOVE_COMPANY_LOGO';
export const PAYOR_UPLOAD_BATCH_DATA = 'PAYOR_UPLOAD_BATCH_DATA';
export const PAYEE_TRANSACTIONS_DATA = 'PAYOR_TRANSACTIONS_DATA';
export const PAYOR_BATCHES_DATA = 'PAYOR_BATCHES_DATA';
export const PAYOR_BATCH_DETAILS = 'PAYOR_BATCH_DETAILS';
export const PAYOR_PAYEE_LIST_DATA = 'PAYOR_PAYEE_LIST_DATA';
export const PAYOR_PAYEE_DETAILED_DATA = 'PAYOR_PAYEE_DETAILED_DATA';
export const PAYOR_DASHBOARD_LATEST_TRANSACTIONS_DATA = 'PAYOR_DASHBOARD_LATEST_TRANSACTIONS_DATA';
export const PAYOR_DASHBOARD_BATCHES_ADDED = 'PAYOR_DASHBOARD_BATCHES_ADDED';
export const PAYOR_DASHBOARD_STATISTICS = 'PAYOR_DASHBOARD_STATISTICS';
export const PAYOR_DASHBOARD_ALERTS = 'PAYOR_DASHBOARD_ALERTS'