import React, { useEffect, useState } from 'react'
import './Sidebar.scss'
import LogoSvg from '../../assets/img/LogoDashboard.svg'
import DividerSvg from '../../assets/img/DividerSidebar.svg'
import DashboardSvg from '../../assets/img/DashboardIcon.svg'
import UserIconSvg from '../../assets/img/UserIcon.svg'
import TransactionIcon from '../../assets/img/TransactionIcon.svg'
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { payorRoutes } from "../../containers/Payor/PayorRoutes";
import { USER_TYPE_ADMIN, USER_TYPE_ENCASHMENT_STORE, USER_TYPE_PAYOR } from '../../utills/Constants'
import { CashCheck, Store } from '../../assets/img'
import { encashmentStoreRoutes } from '../../containers/EncashmentStore/EncashmentStoreRoutes'
import { adminRoutes } from '../../containers/Admin/AdminRoutes'


const Sidebar = (props) => {
    const [currentSidebarItems, setCurrentSidebarItems] = useState([])
    const [selectedKey, setSelectedKey] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const handleItemClick = (path) => {
        navigate(`${path}`);
    };


    function getItem(label, key, icon, path, children, type) {
        return { key, icon, children, label, path, type };
    }

    const SvgIconComponent = ({ source }) => {
        return <img src={source} alt="dashboard-icons" />;
    }


    const DividerSidebar = () => {
        return (
            <div className="opacity-10 py-3 px-4 d-flex flex-column w-100 dropdown-menu-item-devider" >
                <img src={DividerSvg} alt="divider-icon" />
            </div>
        );
    };

    useEffect(() => {
        if (props.USER_TYPE === USER_TYPE_PAYOR) {
            setCurrentSidebarItems([
                getItem('Dashboard', '1', <SvgIconComponent source={DashboardSvg} />, payorRoutes.DEFAULT),
                getItem('Payee Management', '2', <SvgIconComponent source={UserIconSvg} />, payorRoutes.PAYEE_MANAGEMENT),
                getItem('Transactions', '3', <SvgIconComponent source={TransactionIcon} />, payorRoutes.TRANSACTIONS),
                getItem('Settings', '4', <SvgIconComponent source={UserIconSvg} />, payorRoutes.SETTINGS),
                getItem('Support', '5', <SvgIconComponent source={TransactionIcon} />, payorRoutes.SUPPORT),
            ])
        }
        if (props.USER_TYPE === USER_TYPE_ENCASHMENT_STORE) {
            setCurrentSidebarItems([
                getItem('Cash Check', '1', <SvgIconComponent source={CashCheck} />, encashmentStoreRoutes.DEFAULT),
                getItem('Transactions', '2', <SvgIconComponent source={TransactionIcon} />, encashmentStoreRoutes.TRANSACTIONS),
                getItem('Settings', '3', <SvgIconComponent source={UserIconSvg} />, encashmentStoreRoutes.SETTINGS),
                getItem('Support', '4', <SvgIconComponent source={TransactionIcon} />, encashmentStoreRoutes.SUPPORT),
            ])
        }
        if (props.USER_TYPE === USER_TYPE_ADMIN) {
            setCurrentSidebarItems([
                getItem('Dashboard', '1', <SvgIconComponent source={DashboardSvg} />, adminRoutes.DEFAULT),
                getItem('Payor Management', '2', <SvgIconComponent source={TransactionIcon} />, adminRoutes.PAYOR_MANAGEMENT),
                getItem('Store Admin Management', '3', <SvgIconComponent source={Store} />, adminRoutes.STORE_MANAGEMENT),
                getItem('Transactions', '4', <SvgIconComponent source={TransactionIcon} />, adminRoutes.TRANSACTIONS),
                getItem('Companies', '5', <SvgIconComponent source={Store} />, adminRoutes.COMPANIES),
                getItem('Settings', '6', <SvgIconComponent source={UserIconSvg} />, adminRoutes.SETTINGS),
            ])
        }
    }, [props.USER_TYPE])

    useEffect(() => {
        const selectedItem = currentSidebarItems.find(item => item.path === location.pathname);
        if (selectedItem) {
            setSelectedKey(selectedItem.key);
        }
    }, [currentSidebarItems, location.pathname]);

    return (
        <div className='sidebar-container'>
            <div className="p-4 d-flex justify-content-center w-100"><img src={LogoSvg} alt="logo-avs" /></div>
            <DividerSidebar />
            <div className="w-100" >
                <Menu mode="inline" selectedKeys={[selectedKey]} theme="dark" className='menu-sidebar w-90'>
                    {currentSidebarItems.map((item, index) => (
                        <React.Fragment key={item.key}>
                            <Menu.Item key={item.key} icon={item.icon} onClick={() => handleItemClick(item.path)}>{item.label}</Menu.Item>
                            {index === 2 && props.USER_TYPE === USER_TYPE_PAYOR && <DividerSidebar />}
                            {index === 1 && props.USER_TYPE === USER_TYPE_ENCASHMENT_STORE && <DividerSidebar />}
                        </React.Fragment>
                    ))}
                </Menu>
            </div>
        </div>
    )
}

export default Sidebar
