import { axiosInstance, axiosInstanceMultipart } from "../../network/apis/payor/index";

export const login = async (payload) => {
    return await axiosInstance.post('/login', payload);
};

export const updatePayorDetailsById = async (payload) => {
    return await axiosInstance.put(`${payload.id}`, { firstName: payload.firstName, lastName: payload.lastName });
}

export const uploadPayorAccountPhoto = async (payload) => {
    return await axiosInstanceMultipart.put(`${payload.id}/image/upload`, payload.img);
}

export const removePayorAccountPhoto = async (payload) => {
    return await axiosInstance.put(`${payload.id}/image/delete`);
}

export const updatePayorCompanyProfile = async (payload) => {
    return await axiosInstance.put(`/${payload.id}/company`, payload);
}

export const uploadPayorCompanyProfileLogo = async (payload) => {
    return await axiosInstanceMultipart.put(`/${payload.id}/logo/company/${payload.companyId}/upload`, payload.img);
}

export const removePayorCompanyProfileLogo = async (payload) => {
    return await axiosInstance.put(`/${payload.id}/logo/company/${payload.companyId}/delete`);
}

export const uploadPayeeBatchData = async (payload) => {
    return await axiosInstance.post(`/payee/transaction`, payload);
}

export const getPayeeTransactions = async (payload) => {
    return await axiosInstance.post(`/${payload.payorId}/transactions`, payload);
}

export const getBatchList = async (payload) => {
    return await axiosInstance.post(`${payload.payorId}/batch/transactions`, payload)
}

export const getBatchDetails = async (payload) => {
    return await axiosInstance.post(`${payload.payorId}/batch/${payload.batchId}/transactions`, payload)
}

export const getPayees = async (payload) => {
    return await axiosInstance.post(`/${payload.payorId}/payees`, payload);
}

export const getPayeeDetailedData = async (payload) => {
    return await axiosInstance.get(`${payload.payorId}/payee/${payload.payeeId}`);
}

export const getDashboardLatestTransactions = async (payload) => {
    return await axiosInstance.get(`${payload.payorId}/latest/transactions/${payload.filter}`)
}

export const getDashboardBatchesAdded = async (payload) => {
    return await axiosInstance.get(`${payload.payorId}/batches/added/${payload.filter}`)
}

export const getDashboardAlerts = async (payload) => {
    return await axiosInstance.get(`${payload.payorId}/alerts/${payload.filter}`)
}

export const getDashboardStatistics = async (payload) => {
    return await axiosInstance.get(`${payload.payorId}/statistics`)
}