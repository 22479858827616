import PayorRoutes from "../routes/Payor/Payor"
import PayorAuthRoutes from "../routes/Payor/PayorAuthRoutes"
import { payorAuthRoutes, payorRoutes } from "./Payor/PayorRoutes";
import EncashmentStoreRoutes from "../routes/EncashmentStore/EncashmentStore";
import EncashmentStoreAuthRoutes from "../routes/EncashmentStore/EncashmentStoreAuthRoutes";
import { encashmentStoreAuthRoutes, encashmentStoreRoutes } from "./EncashmentStore/EncashmentStoreRoutes";
import { useEffect, useState } from "react";
import history from "../routes/History";
import { getFullAuthObject, getUserSession } from "../utills/CognitoApis";
import { BrowserRouter } from "react-router-dom";
import { PAYOR_LOGIN_INFO } from "../queries/payor/Types";
import { login } from "../queries/payor/Apis";
import { useQuery } from "@tanstack/react-query";
import { USER_TYPE_ADMIN, USER_TYPE_ENCASHMENT_STORE, USER_TYPE_PAYOR } from "../utills/Constants";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import { ENCASHMENT_STORE_LOGIN_INFO } from "../queries/encashmentStore/Types";
import { encashmentStoreLogin } from "../queries/encashmentStore/Apis";
import Loader from "../components/Loader/Loader";
import queryClient from "../queries/queryClient";
import { LOADING } from "../queries/comman/Types";
import { message } from "antd";
import AdminAuthRoutes from "../routes/Admin/AdminAuthRoutes";
import { ADMIN_LOGIN_INFO } from "../queries/admin/Types";
import { adminLogin } from "../queries/admin/Apis";
import { adminAuthRoutes, adminRoutes } from "./Admin/AdminRoutes";
import AdminRoutes from "../routes/Admin/Admin";

const Container = () => {


  const [adminEmail, setAdminEmail] = useState(null)
  const [adminSessionActive, setAdminSessionActive] = useState(false);
  const [encashmentStoreSessionActive, setEncashmentStoreSessionActive] = useState(false)
  const [payorSessionActive, setPayorSessionActive] = useState(false);
  const [payorEmail, setPayorEmail] = useState(null)
  const [encashmentstoreEmail, setEncashmentstoreEmail] = useState(null);
  const isAuthRouteAndSessionActive = Object.values(payorAuthRoutes).includes(window.location.pathname) || Object.values(encashmentStoreAuthRoutes).includes(window.location.pathname) || Object.values(adminAuthRoutes).includes(window.location.pathname);
  const isAnySessions = (payorSessionActive || encashmentStoreSessionActive || adminSessionActive);
  const [tabVisible,setTabVisible] = useState(false);

  const isLoading = queryClient.getQueryData(LOADING)


  useQuery({
    queryKey: [PAYOR_LOGIN_INFO],
    queryFn: () => login({ email: payorEmail }),
    enabled: payorEmail !== null
  }
  )

  useQuery({
    queryKey: [ENCASHMENT_STORE_LOGIN_INFO],
    queryFn: () => encashmentStoreLogin({ email: encashmentstoreEmail }),
    enabled: encashmentstoreEmail !== null
  }
  )

  useQuery({
    queryKey: [ADMIN_LOGIN_INFO],
    queryFn: () => adminLogin({ email: adminEmail }),
    enabled: adminEmail !== null
  }
  )

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      setTabVisible(true)
      // Perform actions when the tab becomes active
    } else {
      setTabVisible(false)
      // Perform actions when the tab becomes inactive
    }
  })

  useEffect(() => {
    if (Object.values(payorAuthRoutes).includes(window.location.pathname) || Object.values(payorRoutes).includes(window.location.pathname)) {
      getUserSession(USER_TYPE_PAYOR).then(response => {
        if (response.error) {
          // Check if window location is not part of auth routes
          if (!Object.values(payorAuthRoutes).includes(window.location.pathname)) {
            window.location.href = payorAuthRoutes.SIGN_IN;
          }
        } else {
          getFullAuthObject(USER_TYPE_PAYOR).then(res => {
            setPayorEmail(res?.attributes?.email)
            setPayorSessionActive(true);
          })
            .catch(err => {
              message.error(err.message)
            })
        }
      });
    }
    if (Object.values(encashmentStoreAuthRoutes).includes(window.location.pathname) || Object.values(encashmentStoreRoutes).includes(window.location.pathname)) {
      getUserSession(USER_TYPE_ENCASHMENT_STORE).then(response => {
        if (response.error) {
          // Check if window location is not part of auth routes
          if (!Object.values(encashmentStoreAuthRoutes).includes(window.location.pathname)) {
            window.location.href = encashmentStoreAuthRoutes.SIGN_IN;
          }
        } else {
          getFullAuthObject(USER_TYPE_ENCASHMENT_STORE).then(res => {
            setEncashmentstoreEmail(res?.attributes?.email)
            setEncashmentStoreSessionActive(true);
          })
            .catch(err => {
              message.error(err.message)
            })

        }
      });
    }
    if (Object.values(adminAuthRoutes).includes(window.location.pathname) || Object.values(adminRoutes).includes(window.location.pathname)) {
      getUserSession(USER_TYPE_ADMIN).then(response => {
        if (response.error) {
          // Check if window location is not part of auth routes
          if (!Object.values(adminAuthRoutes).includes(window.location.pathname)) {
            window.location.href = adminAuthRoutes.SIGN_IN;
          }
        } else {
          getFullAuthObject(USER_TYPE_ADMIN).then(res => {
            setAdminEmail(res?.attributes?.email)
            setAdminSessionActive(true);
          })
            .catch(err => {
              message.error(err.message)
            })
        }
      });
    }
  }, [tabVisible]);


  return (
    <>
      <BrowserRouter history={history}>
        <AdminAuthRoutes />
        <PayorAuthRoutes />
        <EncashmentStoreAuthRoutes />
        <div className="w-100">
          <div className="d-flex">
            {!isAuthRouteAndSessionActive && isAnySessions && <div className="sidebar position-sticky top-0 w-22">
              <Sidebar USER_TYPE={payorSessionActive ? USER_TYPE_PAYOR : encashmentStoreSessionActive ? USER_TYPE_ENCASHMENT_STORE : USER_TYPE_ADMIN} />
            </div>}
            {!isAuthRouteAndSessionActive && isAnySessions && <div className="main-section d-flex flex-column w-80 h-100">
              <Header USER_TYPE={payorSessionActive ? USER_TYPE_PAYOR : encashmentStoreSessionActive ? USER_TYPE_ENCASHMENT_STORE : USER_TYPE_ADMIN} />
              <div className="main-section-content">
                {isLoading && <Loader />}
                {adminSessionActive && <AdminRoutes />}
                {payorSessionActive && <PayorRoutes />}
                {encashmentStoreSessionActive && <EncashmentStoreRoutes />}
              </div>
            </div>}
          </div>
        </div>
      </BrowserRouter>
    </>
  )
}

export default Container