import { adminAuthRoutes, adminRoutes } from "../containers/Admin/AdminRoutes";
import { encashmentStoreAuthRoutes, encashmentStoreRoutes } from "../containers/EncashmentStore/EncashmentStoreRoutes";
import { payorAuthRoutes, payorRoutes } from "../containers/Payor/PayorRoutes";
import { CognitoSignOut } from "./CognitoApis";
import { USER_TYPE_ADMIN, USER_TYPE_ENCASHMENT_STORE, USER_TYPE_PAYOR } from "./Constants";

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const amountWithCommas = (amount)=>{
    return `$${Number(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
}

export const cleanObject = (obj) => {
    Object.keys(obj).forEach(key => {
        if (obj[key] === "") {
            delete obj[key];
        }
    });
    return obj;
}

export const formatPhoneNumber = (number) => {
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return number;
};

export const convertToInternationalFormat = (number) => {
    // Remove all non-digit characters
    const cleaned = number.replace(/\D/g, '');

    // Check if the number starts with +1, if not add it
    let formatted = cleaned;
    if (!cleaned.startsWith('1')) {
        formatted = '1' + cleaned;
    }

    // Return in international format
    return `+${formatted}`;
};

export const routesTable = {
    [USER_TYPE_PAYOR]: {
        signOut: payorAuthRoutes.SIGN_IN,
        settings: payorRoutes.SETTINGS,
    },
    [USER_TYPE_ENCASHMENT_STORE]: {
        signOut: encashmentStoreAuthRoutes.SIGN_IN,
        settings: encashmentStoreRoutes.SETTINGS,
    },
    [USER_TYPE_ADMIN]: {
        signOut: adminAuthRoutes.SIGN_IN,
        settings: adminRoutes.SETTINGS,
    },
};

export const signOut = (USER_TYPE) => {
    const signOutRoute = routesTable[USER_TYPE].signOut;
    if (signOutRoute) {
        CognitoSignOut(USER_TYPE).then(() => {
            window.location.href = signOutRoute;
        });
    }
};