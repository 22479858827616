import React from "react";
import { Route, Routes } from "react-router-dom";
import * as LazyComponent from "../../utills/LazyLoaded.js";
import { adminRoutes } from "../../containers/Admin/AdminRoutes.js";
import { USER_TYPE_ADMIN } from "../../utills/Constants.js";

const AdminRoutes = () => {

    return (
        <Routes>
            <Route exact path={adminRoutes.DEFAULT} element={<LazyComponent.AdminDashboard />} />
            <Route exact path={adminRoutes.STORE_MANAGEMENT} element={<LazyComponent.AdminStoreManagement />} />
            <Route exact path={adminRoutes.PAYOR_MANAGEMENT} element={<LazyComponent.AdminPayorManagement />} />
            <Route exact path={adminRoutes.COMPANIES} element={<LazyComponent.AdminCompanies />} />
            <Route exact path={adminRoutes.TRANSACTIONS} element={<LazyComponent.AdminTransactions />} />
            <Route exact path={adminRoutes.SETTINGS} element={<LazyComponent.AdminSettings USER_TYPE={USER_TYPE_ADMIN} />} />
        </Routes>
    );
};

export default AdminRoutes;
