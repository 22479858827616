import React from 'react'
import './Header.scss'
import { Avatar, Dropdown, Space } from 'antd';
import { payorRoutes } from '../../containers/Payor/PayorRoutes';
import queryClient from '../../queries/queryClient';
import { PAYOR_LOGIN_INFO } from '../../queries/payor/Types';
import { useLocation, useNavigate } from 'react-router-dom';
import { USER_TYPE_ENCASHMENT_STORE, USER_TYPE_PAYOR } from '../../utills/Constants';
import { encashmentStoreRoutes } from '../../containers/EncashmentStore/EncashmentStoreRoutes';
import { ENCASHMENT_STORE_LOGIN_INFO } from '../../queries/encashmentStore/Types';
import { adminRoutes } from '../../containers/Admin/AdminRoutes';
import { ADMIN_LOGIN_INFO } from '../../queries/admin/Types';
import { routesTable, signOut } from '../../utills/GenericFunctions';



const Header = ({ USER_TYPE }) => {
    const navigate = useNavigate();
    const userData = USER_TYPE === USER_TYPE_PAYOR ? queryClient.getQueryData([PAYOR_LOGIN_INFO]) : USER_TYPE === USER_TYPE_ENCASHMENT_STORE ? queryClient.getQueryData([ENCASHMENT_STORE_LOGIN_INFO]) : queryClient.getQueryData([ADMIN_LOGIN_INFO]);
    const location = useLocation();


    const handleRoute = (routeType) => {
        const route = routesTable[USER_TYPE][routeType];
        if (route) {
            navigate(route);
        }
    };
    
    const navToSettings = () => {
        handleRoute('settings');
    };

    const heading = {
        [payorRoutes.DEFAULT]: "Dashboard",
        [payorRoutes.PAYEE_MANAGEMENT]: "Payee Management",
        [payorRoutes.TRANSACTIONS]: "",
        [payorRoutes.SETTINGS]: "Settings",
        [encashmentStoreRoutes.DEFAULT]: "Cash Check",
        [encashmentStoreRoutes.TRANSACTIONS]: "Transactions",
        [encashmentStoreRoutes.SETTINGS]: "Settings",
        [adminRoutes.DEFAULT]: "Dashboard",
        [adminRoutes.STORE_MANAGEMENT]: "Store Admin Management",
        [adminRoutes.TRANSACTIONS]: "Transactions",
        [adminRoutes.PAYOR_MANAGEMENT]: "Payor Management",
        [adminRoutes.COMPANIES]: "Companies"
    }

    const items = [
        {
            key: '1',
            label: (<div onClick={navToSettings}>Settings</div>),
        },
        {
            key: '2',
            label: (<div onClick={() => signOut(USER_TYPE)}>Logout</div>),
        },
    ];

    return (
        <div className="main-section-header d-flex align-items-center mx-4 ">
            <div className="flex-grow-1">
                <div className="text-header">{heading[location.pathname]}</div>
            </div>
            <div className="d-flex gap-4 align-items-center">
                <div className="d-flex gap-2">
                    {/* <div className="bell-button d-flex align-items-center"><img src={BellIcon} alt="search-icon" /></div> */}
                    <Dropdown menu={{ items }} placement="bottomRight" arrow className='cp'>
                        <div className="dropdown-text d-flex align-items-center" >
                            <Space>
                                {userData?.data?.data?.s3ProfileImageURL ? <img alt='user-img' className="userImage-button" src={userData?.data?.data?.s3ProfileImageURL} /> : <Avatar size={50}>{userData?.data?.data?.firstName.charAt(0)}</Avatar>}
                            </Space>
                            {userData?.data?.data.firstName && <div className='ms-2 mt-1'>
                                {userData?.data?.data.firstName + " " + userData?.data?.data.lastName}
                                <div className='role'>{userData?.data?.data.userType}</div>
                            </div>}
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}

export default Header
