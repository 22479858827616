import React from "react";
import "./Loader.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Loader = (props) => {
  return (
    <div style={props.style} className="spinnerContainer d-flex justify-content-center align-items-center h-100">
      <Spin indicator={
      <LoadingOutlined
        style={{
          fontSize: 24,
        }}
        spin
      />
    }/>
    </div>
  );
};

export default Loader;
