const KEY_PAYOR_SESSION = "avs.PAYOR_SESSION";
const KEY_ENCASHMENT_STORE_SESSION = 'avs.ENCASHMENT_STORE_SESSION';
const KEY_ADMIN_SESSION = 'avs.ADMIN_SESSION';

const getValueForKey = (key) => {
  return window.localStorage.getItem(key);
};

const setValueForkey = (key, value) => {
  if (value !== null && value !== undefined) {
    window.localStorage.setItem(key, value);
  }
};

const clearLocalStorage = () => {
  window.localStorage.clear();
};


const getPayorSessionId = () => {
  return getValueForKey(KEY_PAYOR_SESSION);
};

const storePayorSessionId = (session) => {
  console.log("session----", session);
  setValueForkey(KEY_PAYOR_SESSION, session);
};

const getEncashmentStoreSessionId = () => {
  return getValueForKey(KEY_ENCASHMENT_STORE_SESSION)
}

const storeEncashmentStoreSessionId = (session) => {
  return setValueForkey(KEY_ENCASHMENT_STORE_SESSION, session)
}

const getAdminSessionId = () => {
  return getValueForKey(KEY_ADMIN_SESSION);
}

const storeAdminSessionId = (session) => {
  return setValueForkey(KEY_ADMIN_SESSION, session)
}



export {
  getValueForKey,
  setValueForkey,
  clearLocalStorage,
  getPayorSessionId,
  storePayorSessionId,
  getEncashmentStoreSessionId,
  storeEncashmentStoreSessionId,
  getAdminSessionId,
  storeAdminSessionId
};
