export const ADMIN_LOGIN_INFO = 'ADMIN_LOGIN_INFO';
export const ADMIN_DASHBOARD_STATISTICS = 'ADMIN_DASHBOARD_STATISTICS';
export const ADMIN_DASHBOARD_LATEST_TRANSACTIONS = 'ADMIN_DASHBOARD_LATEST_TRANSACTIONS'
export const ADMIN_DASHBOARD_ALERTS = 'ADMIN_DASHBOARD_ALERTS'
export const ADMIN_PAYORS = 'ADMIN_PAYORS'
export const ADMIN_ADD_PAYOR = 'ADMIN_ADD_PAYOR'
export const ADMIN_ADD_FRAUDULENT_REASON = "ADMIN_ADD_FRAUDULENT_REASON"
export const ADMIN_GET_FRAUDULENT_REASON = "ADMIN_GET_FRAUDULENT_REASON"
export const ADMIN_DELETE_FRAUDULENT_REASON = "ADMIN_DELETE_FRAUDULENT_REASON"
export const ADMIN_UPDATE_FRAUDULENT_REASON = "ADMIN_UPDATE_FRAUDULENT_REASON"
export const ADMIN_ACTIVATE_DEACTIVATE_DELETE_PAYOR = 'ADMIN_ACTIVATE_DEACTIVATE_DELETE_PAYOR';
export const ADMIN_GET_ENCASHMENT_STORES = 'ADMIN_GET_ENCASHMENT_STORES';
export const ADMIN_CREATE_ENCASHMENT_USER = 'ADMIN_CREATE_ENCASHMENT_USER';
export const ADMIN_ACTIVATE_DEACTIVATE_DELETE_ENCASHMENT_USER = 'ADMIN_ACTIVATE_DEACTIVATE_DELETE_ENCASHMENT_USER';
export const ADMIN_GET_ALL_TRANSACTIONS = 'ADMIN_GET_ALL_TRANSACTIONS';
export const ADMIN_GET_DETAILED_TRANSACTION = 'ADMIN_GET_DETAILED_TRANSACTION';
export const ADMIN_UPLOAD_ACCOUNT_IMG = 'ADMIN_UPLOAD_ACCOUNT_IMG';
export const ADMIN_REMOVE_ACCOUNT_IMG = 'ADMIN_REMOVE_ACCOUNT_IMG';
export const ADMIN_UPDATE_ACCOUNT_DETAILS = 'ADMIN_UPDATE_ACCOUNT_DETAILS';
export const ADMIN_GET_TRANSACTIONS_FILTERS = 'ADMIN_GET_TRANSACTIONS_FILTERS';
export const ADMIN_GET_STORE_DETAILS = 'ADMIN_GET_STORE_DETAILS';
export const ADMIN_GET_COMPANIES = 'ADMIN_GET_COMPANIES';
