import { message } from "antd";
import { USER_TYPE_ADMIN, USER_TYPE_ENCASHMENT_STORE, USER_TYPE_PAYOR } from "../../utills/Constants";
import { signOut } from "../../utills/GenericFunctions";
import { getUserSession } from "../../utills/CognitoApis";

let consecutive401Count = 0;

export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

export const requestHandler = request => {
  if (isHandlerEnabled(request)) {
    // Modify request here
  }
  return request;
};

export const successHandler = response => {
  if (isHandlerEnabled(response)) {
    // Reset 401 error count on success
    consecutive401Count = 0;
  }
  return response;
};

export const errorHandler = async(error) => {
  if (!error.response) {
    // Handle network or other errors without a response
    message.error('Network error or server not responding');
    return Promise.reject(error);
  }

  const status = error.response.status;
  const errorMessage = error.response.data.message || 'An error occurred';

  switch (status) {
    case 400:
      // Handle bad request errors
      message.error(`${errorMessage}`);
      break;
    case 401:
      consecutive401Count += 1;
      const currentPath = window.location.pathname.split('/');
      const userType = currentPath[1] === 'admin' ? USER_TYPE_ADMIN : currentPath[1] === 'payor' ? USER_TYPE_PAYOR : USER_TYPE_ENCASHMENT_STORE;
      await getUserSession(userType)
      if (consecutive401Count === 3) {
        message.error('Unauthorized. Please log in again.');
        signOut(userType);
        consecutive401Count = 0; // Reset the counter after signing out
      }
      break;
    case 403:
      // Handle forbidden access
      message.error('Forbidden. You do not have the necessary permissions.');
      break;
    case 404:
      // Handle not found errors
      message.error('Not Found. The requested resource could not be found.');
      break;
    case 500:
      // Handle internal server errors
      message.error('Internal Server Error. Please try again later.');
      break;
    default:
      // Handle other errors
      message.error(`Error: ${errorMessage}`);
      break;
  }

  return Promise.reject(error);
};

