import React from "react";
import { Routes, Route } from "react-router-dom";
import * as LazyComponent from "../../utills/LazyLoaded.js";
import { USER_TYPE_ADMIN } from "../../utills/Constants.js";
import { adminAuthRoutes } from "../../containers/Admin/AdminRoutes.js";



const AdminAuthRoutes = () => {

    return (
        <Routes>
            <Route path={adminAuthRoutes.SIGN_IN} element={<LazyComponent.SignIn USER_TYPE={USER_TYPE_ADMIN} />} />
            <Route path={adminAuthRoutes.FORGOT_PASSWORD} element={<LazyComponent.SignIn USER_TYPE={USER_TYPE_ADMIN} forgotPasswordScreen />} />
        </Routes>
    );
};

export default AdminAuthRoutes;
