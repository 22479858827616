exports.payorRoutes = {
    INITIAL: "/",
    DEFAULT: "/payor/dashboard",
    PAYEE_MANAGEMENT: "/payor/payee-management",
    TRANSACTIONS: "/payor/transactions",
    SETTINGS: "/payor/settings",
    SUPPORT: "/payor/support"
};
exports.payorAuthRoutes = {
    //SIGN IN AND OTHER ROUTES
    FORGOT_PASSWORD: "/payor/forgotPassword",
    RESET_PASSWORD: "/payor/resetPassword",
    SIGN_IN:"/payor/signin"
}
