import React from 'react';
import Loader from '../components/Loader/Loader';

const LazyComponent = (importFunction) => {
  const Component = React.lazy(importFunction);
  return (props) => (
    <React.Suspense fallback={<Loader/>}>
      <Component {...props} />
    </React.Suspense>
  );
};

export default LazyComponent;
