export const ENCASHMENT_STORE_LOGIN_INFO = 'ENCASHMENT_STORE_LOGIN_INFO';
export const ENCASHMENT_STORE_DETAILS_UPDATE = 'ENCASHMENT_STORE_DETAILS_UPDATE';
export const ENCASHMENT_STORE_UPDATE_COMPANY_DETAILS = 'ENCASHMEN_STORE_UPDATE_COMPANY_DETAILS';
export const ENCASHMENT_STORE_UPLOAD_ACCOUNT_IMG = 'ENCASHMENT_STORE_UPLOAD_ACCOUNT_IMG';
export const ENCASHMENT_STORE_REMOVE_ACCOUNT_IMG = 'ENCASHMENT_STORE_REMOVE_ACCOUNT_IMG';
export const ENCASHMENT_STORE_UPLOAD_COMPANY_LOGO = 'ENCASHMENT_STORE_UPLOAD_COMPANY_LOGO';
export const ENCASHMENT_STORE_REMOVE_COMPANY_LOGO = 'ENCASHMENT_STORE_REMOVE_COMPANY_LOGO';
export const ENCASHMENT_STORE_GET_PAYEE_CHECKS = 'ENCASHMENT_STORE_GET_PAYEE_CHECKS';
export const ENCASHMENT_STORE_CREATE_STORE = 'ENCASHMENT_STORE_CREATE_STORE';
export const ENCASHMENT_STORE_GET_STORES = 'ENCASHMENT_STORE_GET_STORES';
export const ENCASHMENT_STORE_GET_STORE_DETAILS = 'ENCASHMENT_STORE_GET_STORE_DETAILS';
export const ENCASHMENT_STORE_UPDATE_STORE_DETAILS = 'ENCASHMENT_STORE_UPDATE_STORE_DETAILS';
export const ENCASHMENT_STORE_UPLOAD_STORE_LOGO = 'ENCASHMENT_STORE_UPLOAD_STORE_LOGO';
export const ENCASHMENT_STORE_REMOVE_STORE_LOGO = 'ENCASHMENT_STORE_REMOVE_STORE_LOGO';
export const ENCASHMENT_STORE_ADD_STORE_USER = 'ENCASHMENT_STORE_ADD_STORE_USER';
export const ENCASHMENT_STORE_UPDATE_STORE_USER = 'ENCASHMENT_STORE_UPDATE_STORE_USER';
export const ENCASHMENT_STORE_DELETE_STORE_USER = 'ENCASHMENT_STORE_DELETE_STORE_USER'
export const ENCASHMEN_STORE_GENEARATE_OTP_CODE = 'ENCASHMEN_STORE_GENEARATE_OTP_CODE';
export const ENCASHMENT_STORE_VALIDATE_OTP_CODE = 'ENCASHMENT_STORE_VALIDATE_OTP_CODE';
export const ENCASHMENT_STORE_CASH_CHECK = 'ENCASHMENT_STORE_CASH_CHECK';
export const ENCASHMENT_STORE_GET_FRAUDULENT_REASONS = 'ENCASHMENT_STORE_GET_FRAUDULENT_REASONS';
export const ENCASHMENT_STORE_FLAG_CHECK = 'ENCASHMENT_STORE_FLAG_CHECK';
export const ENCASHMENT_STORE_UPLOAD_DRIVING_LICENSE = 'ENCASHMENT_STORE_UPLOAD_DRIVING_LICENSE';
export const ENCASHMENT_STORE_GET_TRANSACTIONS_FILTERS = 'ENCASHMENT_STORE_GET_TRANSACTIONS_FILTERS';
export const ENCASHMENT_STORE_GET_TRANSACTIONS = 'ENCASHMENT_STORE_TRANSACTIONS';
export const ENCASHMENT_STORE_ACTIVATE_DEACTIVATE_STORE = 'ENCASHMENT_STORE_ACTIVATE_DEACTIVATE_STORE';
export const ENCASHMENT_STORE_ACTIVATE_DEACTIVATE_STORE_USER = 'ENCASHMENT_STORE_ACTIVATE_DEACTIVATE_STORE_USER';
