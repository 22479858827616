import { Amplify } from "aws-amplify";

const configureAdmin= ()=>{
    Amplify.configure({
        Auth: {
            Cognito:{
                region: process.env.REACT_APP_AWS_COGNITO_REGION,
                userPoolId: process.env.REACT_APP_AWS_ADMIN_USER_POOL_ID,
                userPoolClientId:process.env.REACT_APP_AWS_ADMIN_WEBCLIENT_ID
            }
          },
    })
}

const configurePayor= ()=>{
    Amplify.configure({
        Auth: {
            Cognito:{
                region: process.env.REACT_APP_AWS_COGNITO_REGION,
                userPoolId: process.env.REACT_APP_AWS_PAYOR_USER_POOL_ID,
                userPoolClientId:process.env.REACT_APP_AWS_PAYOR_WEBCLIENT_ID
            }
          },
    })
}


const configureEncashmentStore= ()=>{
    Amplify.configure({
        Auth: {
            Cognito:{
                region: process.env.REACT_APP_AWS_COGNITO_REGION,
                userPoolId: process.env.REACT_APP_AWS_ENCASHMENT_STORE_USER_POOL_ID,
                userPoolClientId:process.env.REACT_APP_AWS_ENCASHMENT_STORE_WEBCLIENT_ID
            }
          },
    })
}


export {configureAdmin,configurePayor,configureEncashmentStore}