exports.encashmentStoreRoutes = {
    INITIAL: "/",
    DEFAULT: "/encashmentstore/cashcheck",
    TRANSACTIONS: "/encashmentstore/transactions",
    SETTINGS:"/encashmentstore/settings"
};
exports.encashmentStoreAuthRoutes = {
    //SIGN IN AND OTHER ROUTES
    FORGOT_PASSWORD: "/encashmentstore/forgotPassword",
    RESET_PASSWORD: "/encashmentstore/resetPassword",
    SIGN_IN:"/encashmentstore/signin"
}
