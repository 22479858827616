import React from "react";
import { Routes,Route } from "react-router-dom";
import * as LazyComponent from "../../utills/LazyLoaded.js";
import { payorAuthRoutes as payorAuthRoutesPath } from "../../containers/Payor/PayorRoutes.js";
import { USER_TYPE_PAYOR } from "../../utills/Constants.js";



const PayorAuthRoutes = () => {

  return (
        <Routes>
          <Route path={payorAuthRoutesPath.SIGN_IN} element={<LazyComponent.SignIn USER_TYPE={USER_TYPE_PAYOR}/>} />
          <Route path={payorAuthRoutesPath.FORGOT_PASSWORD}  element={<LazyComponent.SignIn USER_TYPE={USER_TYPE_PAYOR} forgotPasswordScreen/>} />
        </Routes>
  );
};

export default PayorAuthRoutes;
